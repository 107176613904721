import axios from 'axios';
import { getLanAddress } from "../helpers/network";
import { getFrom } from "../helpers/server";
import { PHP_ENTRYPOINT } from '../config/backend';
import { info, logger } from '../helpers/logger';
import { ArtecoToast } from '../components/Toast/ArtecoToast';
import { getCurrentServerTime } from '../helpers/timeHelpers';

export default class ArtecoServer {
  constructor(server) {

    this.serverRef = server;    
    this._id = server._id;
    this.name = server.name;
    this.nodeServer = server.nodeServer;
    this.websocket = null;
    this.codeName = server.codeName;

    this.PING_INTERVAL = 25000; // Intervallo di tempo in millisecondi tra i messaggi di ping
    this.SESSION_DURATION_HOURS = 3;
    this.SESSION_DURATION = this.SESSION_DURATION_HOURS*60*60; // durata della sessione in secondi
    this.PING_COUNT = 0;
    this.PING_MAX = this.SESSION_DURATION / (this.PING_INTERVAL / 1000);
    this.pingTimeout = null;
  }

  connectSocket() {
    logger(info, 'socket', ">>> server " + this.serverRef.name + " Socket connect");
    const serverIp = this.serverRef.isLocal ? getLanAddress(this.serverRef.ip) : this.serverRef.ip;

    if(!serverIp || !this.serverRef.port) {      
      return false;
    }
    
    //AST-4049
    const access_token = this.serverRef.access_token !== "" ? this.serverRef.access_token : "renew";
    
    logger(info, 'socket', ">>> server " + this.serverRef.name + " ServerIp : " + serverIp + "Server port " + this.serverRef.port );
    this.websocket = (this.serverRef.nodeServer) ?
    new WebSocket(`wss://${serverIp}:${this.serverRef.port}/~ws~/`, `${access_token}`) :
    new WebSocket(`wss://${serverIp}:${this.serverRef.port}/~ws~/`);

    logger(info, 'socket', ">>> server " + this.serverRef.name + " WebSocket created " + this.websocket.readyState );
  
    this.websocket.onopen = () => {
      logger(info, 'socket', ">>> server " + this.serverRef.name + " Arteco Server Opens WS connection - Start Ping");
      // Inizia a inviare messaggi di ping ogni PING_INTERVAL millisecondi
      this.pingTimeout = setInterval(() => {
        logger(info, 'socket', ">>> server " + this.serverRef.name + " Send PING , PING_COUNT : " + this.PING_COUNT + "/" + this.PING_MAX);
        this.websocket.send('ping'); // Invia un messaggio di ping al server

        this.PING_COUNT++;
        if(this.PING_COUNT >= this.PING_MAX) {
          this.closeConnection();
        }

      }, this.PING_INTERVAL); 

      // Simula la disconnessione dopo 10 secondi con code 1006
      // setTimeout(() => {
      //   if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
      //     logger(info, 'socket', ">>> server " + this.serverRef.name + " Simulating socket error with code 1006");
      //     this.websocket.close(4999, "Simulated disconnection error");
      //   }
      // }, 10000); // 10 secondi
    };

    this.websocket.onclose = () => {
        logger(info, 'socket', ">>> server " + this.serverRef.name + " Arteco Server Close WS connection - Reset Ping");
      // Pulisce il timeout del ping
      clearTimeout(this.pingTimeout);
    };

    this.websocket.onerror = (error) => {
      logger(info, 'socket', ">>> server " + this.serverRef.name + " Arteco Server WS error " + error.message);
    };
    

  }


  addSocketListener(event, callback) {
    //console.log(`>>: Server ${this.serverRef.name}: added event listener ${event}`);
    
    this.websocket && this.websocket.addEventListener(event, (e) => {
      callback(e);
    })
  }

  closeConnection() {
    this.websocket && this.websocket.close();
  }

  socketConnected() {
    if (!this.websocket) return false;
    return (this.websocket.readyState === WebSocket.OPEN);
  }

  socketClosed() {
    if (!this.websocket) return false;
    return (this.websocket.readyState === WebSocket.CLOSED);
  }

  isConnecting = (socket) => {
    if (!this.websocket) return false;
    return (this.websocket.readyState === WebSocket.CONNECTING);
  }

  getApiV2BaseUrl() {
    const serverIp = (this.serverRef.isLocal) ? getLanAddress(this.serverRef.ip) : this.serverRef.ip;

    let config = this.serverRef.nodeServer ? {
      headers: {
        'Authorization': 'Bearer ' + this.serverRef.access_token
      }
    } : {};

    delete axios.defaults.headers.common.Authorization;

    return {
      baseUrl: `${this.serverRef.protocol}://${serverIp}:${this.serverRef.port}/${PHP_ENTRYPOINT}`,
      config
    }
  }

  getLastEvents(userPrefs) {
    //eventi nel booco
    const { baseUrl, config } = this.getApiV2BaseUrl();
    
    const from = getFrom(this.serverRef, userPrefs);
    const to = getCurrentServerTime();
    
    return axios.get(`${baseUrl}/events?from=${from}&SearchMode=lastUpdate&to=${to}&untilNow=true`, config);

  }


  getIntegrations() {
    
    const { baseUrl, config } = this.getApiV2BaseUrl();
    return axios.get(`${baseUrl}/integration/get-all`, config)      

  }

  
  sendMessage(message) {
    const isConnected = this.socketConnected();
    if(isConnected) {
      this.websocket.send(message);
    }
  }

  addIntegration(what, config) {
    switch (what) {
      case 'ksenia':

        
        break;
    
      default:
        break;
    }
  }

  removeInitialSlash = (str) => {
      return str.replace(/^\//, '');
  }

  sendCommand(event, callbackOk = null, callbackError = null) {
    const { baseUrl, config } = this.getApiV2BaseUrl();      
    
    const route = this.removeInitialSlash(event.route);
    if(route) {
      const command = {...event};
      delete command.route;
      axios.post(`${baseUrl}/${route}`, command, config)
      .then(ans => {
        const response = ans?.data;
        if(response.statusCode === 200) {
          callbackOk && callbackOk();
          ArtecoToast("success", response.message);
        } else {
          callbackError && callbackError();
          ArtecoToast("error", response.message);
        }
      })
      .catch(error => {
        callbackError && callbackError();
        error && ArtecoToast("error", error.message);
      })
    }
  }
}