import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//actions
import { forceLoginByCodename } from '../actions/serverActions';
import { updateConnectedUsersAll, updateUserInfo, updateUserPosition } from '../actions/authActions';
import { getConnectedServers, getConnectedServersCodenames, OmniaLight } from '../reducers/serversReducer';
import { getUserPrefs } from '../reducers/userPrefsReducer';
import { appPaths } from '../config/paths';
import { activateLayout, refreshLayout } from '../actions/layoutActions';
import { addCustomListener } from '../actions/documentActions';
import { listenerExists } from '../reducers/documentReducer';
import { getLayouts } from '../reducers/layoutsReducer';
import { ArtecoToast } from '../components/Toast/ArtecoToast';
import { withTranslation } from 'react-i18next';
import { updateUserPrefs } from '../actions/userPrefsActions';
import { info, logger } from '../helpers/logger';
import { getAuthentication, getAuthenticationUserId, getUserBySocketId, isAbleToChat } from '../reducers/authReducer';
export class ComunicationManager extends Component {
  constructor (props) {
    super(props);

    this.state = {
      connected: false,
      lastServerListSent: ""
    }

    this.socketCheckInterval = null;
  }

  componentDidMount() {
    const { connected } = this.state;

    if (this.props.userPrefs && !connected) {
      this.setState({
        connected: true
      }, () => {
        const { updateUserInfo, updateConnectedUsersAll, updateUserPosition, activateLayoutListenerExist, addCustomListener, t } = this.props;

        //receiving socket id from server                
        this.props.socket.on("yourID", (id) => {

          this.setState({
            yourID: id
          });

          const userInfo = {
            socketId: id,
            name: this.props.auth.user.name,
            email: this.props.auth.user.email,
            userId: this.props.auth.user.id,
            visible: this.props.userPrefs.visible
          }

          this.props.socket.id = id;

          updateUserInfo(userInfo);
          this.props.socket.send("userInfo", userInfo);
          logger(info, "ComunicationManager", `${this.props.auth.user.email} ${this.props.socket.id} >>>>>> sending user info`);

          const options = {
            enableHighAccuracy: false,
            timeout: 2000,
            maximumAge: 27000
          };

          navigator.geolocation.watchPosition(this.wait(this.updatePosition, 3000), this.positionDenied, options);

        })




        //receiving user list from server
        this.props.socket.on("allUsers", (data) => {
          const userList = Object.keys(data);

          if (userList.length > 0) {
            updateConnectedUsersAll(data)
          }

        })


        this.props.socket.on("userMoved", (data) => {
          updateUserPosition(data);
        })


        //resending user info if not received        
        this.socketCheckInterval = setInterval(() => {
          if (!this.props.currentUser && this.props.auth.user.email) {
            //console.log("resending user info");
            this.props.socket.send("userInfo", {
              socketId: this.props.socket.id,
              name: this.props.auth.user.name,
              email: this.props.auth.user.email,
              userId: this.props.auth.user.id,
              visible: this.props.userPrefs.visible,
              inFocus: true
            });
          }
        }, 3000);


        //activate specific layout
        this.props.socket.on("activateLayout", (data) => {
          const sharedLayout = data.layout;
          const serverCodenames = data.serverCodenames;
          const isMine = sharedLayout.owner === this.props.auth.user.id;

          if (sharedLayout && !isMine) {

            const isSharedWithMe = sharedLayout.shareList.includes(this.props.auth.user.id);
            const wasSharedWithMe = this.props.layouts.layouts.find(layout => layout._id === sharedLayout._id);

            const isNewShare = isSharedWithMe && !wasSharedWithMe;
            const isStoppingShare = wasSharedWithMe && !isSharedWithMe;

            if (!this.props.userPrefs.getUpdates) {
              if (isStoppingShare) {
                this.layoutRefresher(sharedLayout, serverCodenames, isNewShare, isStoppingShare);
              }

              if (isNewShare) {
                ArtecoToast('layout-update', sharedLayout.ownerName + t('SHARED_LAYOUT_SHARED'), '', () => this.layoutRefresher(sharedLayout, serverCodenames, isNewShare, isStoppingShare), null);
              }

              if (wasSharedWithMe && !isStoppingShare) {
                ArtecoToast('layout-update', sharedLayout.ownerName + t('SHARED_LAYOUT_UPDATED_A_LAYOUT') + sharedLayout.label + ".", '', () => this.layoutRefresher(sharedLayout, serverCodenames, isNewShare, isStoppingShare), null);
              }
            }

            if (this.props.userPrefs.getUpdates && (wasSharedWithMe || isNewShare || isStoppingShare)) {
              this.layoutRefresher(sharedLayout, serverCodenames, isNewShare, isStoppingShare);
            }
          }
        })


        if (!activateLayoutListenerExist) {
          addCustomListener('sendLayoutUpdates', (layout) => {
            this.props.socket.send("sendLayoutUpdates", layout)
          })
        }

      })
    }
  }


  positionDenied = () => {
    this.setState({
      debugMessage: "NO GPS"
    })
  }

  socketChanged = (oldSocket, newSocket) => {
    if (!oldSocket && !newSocket) return false;
    if ((!oldSocket && newSocket) || (oldSocket && !newSocket)) return true;

    if (oldSocket.id !== newSocket.id) return true;
  }

  updatePosition = (position) => {
    const { yourID } = this.state;
    const { auth } = this.props;

    const userInfo = {
      socketId: yourID,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      name: auth.user.name,
      email: auth.user.email
    }


    this.props.socket.send("userMove", userInfo);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.userPrefs.visible !== this.props.userPrefs.visible ||
      this.socketChanged(this.props.socket, nextProps.socket) ||
      nextState.yourID !== this.state.yourID ||
      nextState.connected !== this.state.connected ||
      nextProps.isOmniaLight !== this.props.isOmniaLight ||
      nextProps.ableToChat !== this.props.ableToChat ||
      nextProps.userId !== this.props.userId
    ) {
      return true;
    }
    return false;
  }

  wait = (callback, ms) => {
    ms = ms || 5000;// default wait time (millisecond)
    var wait = Date.now();
    return function () {
      var ctx = this, evt = arguments, now = Date.now();
      if (wait <= now) {
        wait = now + ms;// define next timestamp to reach
        callback.apply(ctx, evt);
      }
    };
  }

  //Layout Refresh Function
  layoutRefresher = (LayoutToRefresh, allServerCodenames, ifNewSharedLayout, ifStoppedSharing) => {
    const { history, refreshLayout, forceLoginByCodename } = this.props;

    refreshLayout(LayoutToRefresh, appPaths.dashboard, history, this.props.auth.user.id, ifNewSharedLayout, ifStoppedSharing);

    if (allServerCodenames && allServerCodenames.length > 0) {
      allServerCodenames.map(serverCodename => {
        if (!this.props.serversCodenames.includes(serverCodename)) {
          forceLoginByCodename({ codeName: serverCodename });
        }
      })
    }
  }


  componentDidUpdate(prevProps, prevState) {
    const { ableToChat } = this.props;

    if (ableToChat !== prevProps.ableToChat) {
      const visibility = ableToChat ? true : false;
      this.props.socket.send("updateVisibility", { socketId: this.props.socket.id, visible: visibility });      
    }
  }

  render() {
    return (
      <></>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateUserPrefs: updatedUserPrefs => dispatch(updateUserPrefs(updatedUserPrefs)),
    updateUserInfo: (userInfo) => dispatch(updateUserInfo(userInfo)),
    updateConnectedUsersAll: (data) => dispatch(updateConnectedUsersAll(data)),
    updateUserPosition: (data) => dispatch(updateUserPosition(data)),
    activateLayout: (layout, where) => dispatch(activateLayout(layout, where)),
    addCustomListener: (listener, payload, callback) => dispatch(addCustomListener(listener, payload, callback)),
    refreshLayout: (layout, redirectTarget, history, userId, isNewShare, isStoppingShare) => dispatch(refreshLayout(layout, redirectTarget, history, userId, isNewShare, isStoppingShare)),
    forceLoginByCodename: (codename) => dispatch(forceLoginByCodename(codename)),
    dispatch
  }
}

const mapStateToProps = (state, ownProps) => ({
  ableToChat: isAbleToChat(state),
  isOmniaLight: OmniaLight(state),
  layouts: getLayouts(state),
  auth: getAuthentication(state),
  servers: getConnectedServers(state),
  serversCodenames: getConnectedServersCodenames(state),
  userPrefs: getUserPrefs(state),
  activateLayoutListenerExist: listenerExists(state, 'sendLayoutUpdates'),
  userId: getAuthenticationUserId(state),
  currentUser: getUserBySocketId(state, ownProps.socket.id)
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation()(ComunicationManager)));